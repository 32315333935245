export default {
  install(Vue) {
    Vue.prototype.$notifications = () => {
      return {
        CONTACT_IN_SUPPRESSION_ERROR: "Contact Suppression failed try again",
        CONTACT_IN_SUPPRESSION: "Contact is present in Suppression",
        CONTACT_CREATATION_ERROR: "Contact Not saved",
        CONTACT_CREATED: "Contact saved Successfully",
        CLIENT_CREATED: "Client Created Successfully",
        CLIENT_ERROR: "Could Not Create Client",
        CLIENT_UPDATED: "Client Updated Successfully",
        CLIENT_UPDATE_ERROR: "Could Not Update Client",
        FETCH_CLIENT_ERROR: "Could Not Fetch Clients",
        CLIENT_EXISTS: "Client Already Exists",
        PROJECT_ERROR: "Could Not Create Project",
        PROJECT_DELETE_SUCCESS: "Project Deleted Successfully",
        PROJECT_DELETE_ERROR: "Could Not Delete Project",
        FILE_UPLOAD_HEADER_ERROR: "File Upload Failed, Duplicate Headers Found",
        FILE_UPLOADED: "File Uploaded Successfully",
        FILE_UPLOAD_ERROR: "Could Not Upload File",
        FILE_SUCCESS: "File Downloaded Successfully",
        FILE_ERROR: "Could Not Download File",
        FETCH_FILE_ERROR: "Could Not Fetch File",
        DOWNLOAD_JOB_SUBMISSION_SUCCESS: "Download Job Submitted Successfully",
        FETCH_PROJECT_ERROR: "Could Not Fetch Projects",
        PROJECT_FILTER_SUCCESS: "Filter Applied Successfully",
        PROJECT_SETUP_SUCCESS: "Project Setup Saved Successfully",
        PROJECT_SETUP_ERROR: "Could Not Save Setup",
        FILE_DOWNLOAD_SUCCESS: "File Downloaded Successfully",
        FILE_DOWNLOAD_ERROR: "Could Not Download File",
        FILE_DELETE: "File Deleted Successfully",
        FILE_DELETE_ERROR: "Could Not Delete File",
        PROJECT_SPEC_SUCCESS: "Project Specifications Saved Successfully",
        PROJECT_SPEC_ERROR: "Could Not Save Project Specifications",
        PROJECT_NOT_FOUNDED: "Project Not Found",
        PROJECT_SPEC_NOTFOUNDED: "Project Spec Not Found",
        STATUS_SUCCESS: "Status Refreshed Successfully",
        STATUS_ERROR: "Could Not Refresh Status",
        ERROR_REPORT_DOWNLOAD_SUCCESS: "Report Downloaded Successfully",
        ERROR_REPORT_DOWNLOAD_ERROR: "Could Not Download Report",
        SORT_FILTER_ERROR: "Could Not Apply Sort or Filters",
        PROJECT_SPEC_ERROR_REASON: "Mandatory Field Required",
        TASK_LIST_ERROR: "Could Not Fetch Task-List",
        CONTACT_DISPOSED: "Contact Disposed Successfully",
        FORM_VALIDATION_ERROR:
          "Please Enter All Mandatory Fields With Valid Data",
        Dispose_Contact_Shortcut_ERROR:
          "Dispose Contact Shortcut Disable, Contact Form Invalid",
        COPY_CLIPBOARD_SUCCESS: "Copied to ClipBoard",
        COPY_CLIPBOARD_ERROR: "Can not copy",
        CONTACT_DATA_TABLE_GET_ERROR: "Unable to Fetch Contacts",
        TEMPLATE_UPDATE_SUCCESS: "Template Saved Successfully",
        TEMPLATE_UPDATE_FAILED: "Could Not Save Template",
        UNLINK_FILE: "File Un-Linked Successfully",
        UNLINK_FILE_ERROR: "Could Not Un-Link File",
        LINK_FILE: "Files Linked Successfully",
        LINK_FILE_ERROR: "Could Not Link Files",
        APPLY_FILTER_ERROR: "Could Not Apply Filter",
        ADD_AGENTS_TASK_ALLOCATION: "Please Add Agents",
        ADD_STRATEGY_TASK_ALLOCATION: "Please Select Allocation Strategy",
        ASSIGN_TASK_FAILED: "Failed to Assign Tasks",
        TASK_STATS_ERROR: "Counts Not Get Tasks Stats For This Project",
        FILENAME_NOT_FOUND: "File name is required",
        FILE_CONTENT_TYPE_ERROR:
          "File Upload Failed,uploaded file type is not allowed. Please upload a valid file format",
      };
    };
  },
};
